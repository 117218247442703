<template>
  <div class="search-ponents">
    <div class="title">
      <h1>我的產品</h1>
      <span>MY PRODUCT</span>
    </div>
    <el-row
      v-if="isShowBlank"
      :gutter="30"
      class="product-row"
    >
      <el-col
        v-if="custWaterProdList.length === 0"
        class="noData"
      >
        尚無資料
      </el-col>
      <el-col
        v-for="(item) in custWaterProdList"
        :key="item.CustProdId"
        :sm="12"
      >
        <div
          class="container-card"
          style="margin-bottom:3rem"
          @click="gotoProduct(item)"
        >
          <el-card>
            <div class="product-top">
              <div class="imgs">
                <img
                  v-lazy="item.ProdImage"
                  alt=""
                >
                <!-- <img
                  v-if="item.ProdLineName === '除油煙機'"
                  src="../assets/img/除油煙機.png"
                >
                <img
                  v-else-if="item.ProdLineName === '熱水器'"
                  src="../assets/img/熱水器.png"
                > -->
              </div>
              <div
                class="btn"
                @click="gotoProduct(item)"
              >
                <!-- <span @click="$router.push({  path: `/toproduct/${item.CustProdId}` })">更換濾心</span> -->
                <span v-if="item.ProdLineName === '除油煙機'">查看油網</span>
                <span v-else-if="item.ProdLineName === '淨水器'">更換濾心</span>
              </div>
            </div>
            <div class="name">
              <h1>{{ item.ProdLineName }}</h1>
            </div>
            <div class="tex">
              <h1>型號：{{ item.ModelTypeName }}</h1>
              <span>製造號碼：{{ item.MFNO }}</span>
            </div>
            <div class="map">
              安裝地址：{{ item.Addr }}
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- 產品為空 -->
    <blank v-else />
    <Loading :show="isShowLoading" />
  </div>
</template>

<script>

// import ProductList from './components/ProductList'
import * as _ from 'lodash'
import Blank from '@/components/Blank'
import { getCustomerByLineMid, getCustByEncodeProdcutId, getCustWaterProdList } from '../api/api'
import storage from './../storage'

export default {
  name: 'SearchPonents',
  components: {
    Blank
  },
  data () {
    return {
      isShowLoading: true,
      isShowBlank: true,
      custWaterProdList: [],
      EncodeProductId: '',
      LINEMid: ''
    }
  },
  async mounted () {
    await this.liffInit(process.env.VUE_APP_LIFFID_SEARCH_PRODUCTS)
    this.EncodeProductId = storage.getItem('EncodeProductId')
    this.LINEMid = storage.getItem('LINEMid')
    console.log('encode', this.EncodeProductId, this.LINEMid)
    // todo:假資料待移除
    if (!this.EncodeProductId) {
      this._getCustomerByLineMid()
    } else {
      this._getCustByEncodeProdcutId()
    }
    // this.custWaterProdList = [
    //   {
    //     CustProdId: '74cca67a-a9da-ec11-81f6-005056af7974',
    //     ProdLineId: 'bfe7586b-b30d-e611-80c5-000c29188d63',
    //     ProdLineName: '除油煙機',
    //     ProdLineCode: 1,
    //     ProdCateId: 'e5015c51-7a17-e611-80c5-000c29188d63',
    //     ProdCateName: '除油煙機',
    //     ProdCateCode: null,
    //     ProdSeriesCode: 'A0',
    //     ModelTypeId: 'aeacde3f-b2db-e611-80c5-005056af7974',
    //     ModelTypeName: 'DR3592AXL',
    //     MFNO: '1A00000999916',
    //     Addr: '420-台中市中港路1號',
    //     InstallDate: '2022-05-21',
    //     IsOriginalService: true,
    //     OilType: '錐網',
    //     OilDeliverTime: '2022-05-31',
    //     ProdImage: 'https://buy.sakura.com.tw/media/6207/P0230.png',
    //     MaintainFlag: '定期保養',
    //     IsOldWaterProduct: false,
    //     Charge: 0,
    //     Detail: null
    //   },
    //   {
    //     CustProdId: '410b1f65-a9da-ec11-81f6-005056af7974',
    //     ProdLineId: 'c3e7586b-b30d-e611-80c5-000c29188d63',
    //     ProdLineName: '熱水器',
    //     ProdLineCode: 3,
    //     ProdCateId: '38092edc-4522-e611-80d1-000c29188d63',
    //     ProdCateName: '瓦斯爐',
    //     ProdCateCode: null,
    //     ProdSeriesCode: 'B6',
    //     ModelTypeId: '2bd10254-35ba-ec11-81f6-005056af7974',
    //     ModelTypeName: 'G2921GBN',
    //     MFNO: '1A0000065456',
    //     Addr: '420-台中市3000號',
    //     InstallDate: '2022-05-21',
    //     IsOriginalService: true,
    //     OilType: '',
    //     OilDeliverTime: '',
    //     ProdImage: 'https://buy.sakura.com.tw/media/6207/P0230.png',
    //     MaintainFlag: '定期保養',
    //     IsOldWaterProduct: false,
    //     Charge: 0,
    //     Detail: null
    //   },
    //   {
    //     CustProdId: '5ed4982d-be91-eb11-81df-005056af7974',
    //     ProdLineId: 'c5e7586b-b30d-e611-80c5-000c29188d63',
    //     ProdLineName: '淨水器',
    //     ProdLineCode: 4,
    //     ProdCateId: '3a092edc-4522-e611-80d1-000c29188d63',
    //     ProdCateName: '淨水器',
    //     ProdCateCode: null,
    //     ProdSeriesCode: 'R2',
    //     ModelTypeId: 'ae8f8fff-b997-e911-81ae-005056af7974',
    //     ModelTypeName: 'P0230',
    //     MFNO: 'Z',
    //     Addr: '420-台中市3000號',
    //     InstallDate: '2021-03-31',
    //     IsOriginalService: false,
    //     OilType: '',
    //     OilDeliverTime: '',
    //     ProdImage: 'https://buy.sakura.com.tw/media/6207/P0230.png',
    //     MaintainFlag: '自行DIY',
    //     IsOldWaterProduct: false,
    //     Charge: 0,
    //     Detail: [
    //       {
    //         Sequence: '1',
    //         LastChangeDate: '2021-03-31',
    //         SuggestChangeDate: '2021-10-31',
    //         FilterMfno: '',
    //         FilterName: 'F0161',
    //         FilterDescription: '複合式濾芯(活性碳+PP)',
    //         FilterDetail:
    //               '過濾頭髮、泥沙、鐵銹、雜質等固體沈澱物、氯、異色、異味。',
    //         FilterSize: '',
    //         RemainLife: '10%',
    //         RemainDays: 0,
    //         IsNeedToChange: true,
    //         IsBinding: true
    //       },
    //       {
    //         Sequence: '2',
    //         LastChangeDate: '2021-03-31',
    //         SuggestChangeDate: '2022-04-30',
    //         FilterMfno: '',
    //         FilterName: 'F0152',
    //         FilterDescription: '後置活性碳',
    //         FilterDetail: '過濾氯、異色、異味，提升口感。',
    //         FilterSize: '',
    //         RemainLife: '80%',
    //         RemainDays: 0,
    //         IsNeedToChange: true,
    //         IsBinding: true
    //       },
    //       {
    //         Sequence: '3',
    //         LastChangeDate: '2021-03-31',
    //         SuggestChangeDate: '2023-04-30',
    //         FilterMfno: '',
    //         FilterName: 'F0181',
    //         FilterDescription: 'RO膜(400G)',
    //         FilterDetail:
    //               '過濾氯化物、農藥、致癌物質三氯甲烷、重金屬、細菌。',
    //         FilterSize: '',
    //         RemainLife: '32%',
    //         RemainDays: 244,
    //         IsNeedToChange: false,
    //         IsBinding: true
    //       }
    //     ]
    //   },
    //   {
    //     CustProdId: '6f0af701-be91-eb11-81df-005056af7974',
    //     ProdLineId: 'c5e7586b-b30d-e611-80c5-000c29188d63',
    //     ProdLineName: '淨水器',
    //     ProdLineCode: 4,
    //     ProdCateId: '3a092edc-4522-e611-80d1-000c29188d63',
    //     ProdCateName: '淨水器',
    //     ProdCateCode: null,
    //     ProdSeriesCode: 'R1',
    //     ModelTypeId: 'd209e325-e993-ea11-81b2-005056af7974',
    //     ModelTypeName: 'P0780',
    //     MFNO: 'Z',
    //     Addr: '420-台中市3000號',
    //     InstallDate: '2021-03-31',
    //     IsOriginalService: false,
    //     OilType: '',
    //     OilDeliverTime: '',
    //     ProdImage: 'https://buy.sakura.com.tw/media/8094/P0780.png',
    //     MaintainFlag: '自行DIY',
    //     IsOldWaterProduct: false,
    //     Charge: 0,
    //     Detail: [
    //       {
    //         Sequence: '1',
    //         LastChangeDate: '2021-03-31',
    //         SuggestChangeDate: '2022-01-31',
    //         FilterMfno: '',
    //         FilterName: 'F0271',
    //         FilterDescription: 'AF濾心',
    //         FilterDetail:
    //               '利用礦物纖維「靜電吸附」有效濾除水中病菌，如大腸桿菌、金黃色葡萄球菌等。',
    //         FilterSize: '',
    //         RemainLife: '0%',
    //         RemainDays: 0,
    //         IsNeedToChange: true,
    //         IsBinding: true
    //       },
    //       {
    //         Sequence: '2',
    //         LastChangeDate: '2021-03-31',
    //         SuggestChangeDate: '2022-04-30',
    //         FilterMfno: '',
    //         FilterName: 'F0231',
    //         FilterDescription: '活性碳纖維',
    //         FilterDetail:
    //               '過濾大於0.5微米雜質，石棉纖維、泥沙、鐵鏽、等固體沉澱物，水中污染物，有效吸附水中餘氯與有機物，去除異色、異味。',
    //         FilterSize: '',
    //         RemainLife: '80%',
    //         RemainDays: 0,
    //         IsNeedToChange: true,
    //         IsBinding: true
    //       }
    //     ]
    //   }
    // ]
    // this.isShowLoading = false
  },
  methods: {
    _getCustByEncodeProdcutId () {
      const { EncodeProductId } = this
      getCustByEncodeProdcutId({
        EncodeProductId
      }).then(res => {
        if (res.status === 200) {
          storage.setItem('CustId', res.data.Data.CustId)
          this._getCustWaterProdList()
        }
      })
    },
    _getCustomerByLineMid () {
      console.log('getCustomerByLineMid')
      const { LINEMid } = this
      getCustomerByLineMid({
        LINEMid
      }).then(res => {
        if (res.status === 200) {
          storage.setItem('CustId', res.data.Data.CustId)
          console.log('call API 1')
          this._getCustWaterProdList()
        }
      }).catch(() => {
        this.isShowLoading = false
      })
    },
    _getCustWaterProdList () {
      storage.setItem('EncodeProductId', this.EncodeProductId)
      storage.setItem('LINEMid', this.LINEMid)
      const CustId = storage.getItem('CustId')
      console.log('CustId =', CustId)
      getCustWaterProdList({
        CustId
      }).then(res => {
        storage.setItem('CustProdId', _.get(res, 'data.Data[0].CustProdId') || '')
        console.log('CustProdId =', storage.getItem('CustProdId'))
        console.log('res', _.get(res, 'data.Data[0].CustProdId'))
        this.custWaterProdList = res.data.Data || []
      }).finally(() => {
        this.isShowLoading = false
      })
    },
    gotoProduct (item) {
      // Detail有資料 或 產品為除油煙機 可點選
      if (item.Detail || item.ProdLineName === '除油煙機') {
        if (!this.EncodeProductId) {
          this.$router.push({ path: '/toproduct', query: { mid: this.LINEMid, custProdId: item.CustProdId } })
        } else {
          this.$router.push({ path: '/toproduct', query: { productid: this.EncodeProductId, custProdId: item.CustProdId } })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .search-ponents {
    .title {
      margin-bottom: 3rem;
      text-align: center;
      color: #131313;
      h1 {
        line-height: 4.4rem;
        font-size: 3.1rem;
        font-weight: lighter;
      }
      span {
        line-height: 1.7rem;
        font-size: 1.2rem;
        font-weight: lighter;
      }
    }
    .container-card {
  width: 100%;
  .el-card {
    border-radius: 1.4rem;
    cursor: pointer;
    // height: 23.7rem;

  }
  .product-top {
    display: flex;
    justify-content: space-between;
    .imgs {
      width: 8.6rem;
      height: 8.6rem;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
      }
    }
    .btn {
      span {
        width: 8rem;
        height: 2.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .4rem;
        background: rgba(255, 244, 192, 1);
        font-size: 1.2rem;
        color: rgba(233, 154, 0, 1);
      }
    }
  }
  .tex {
    margin-top: 1.5rem;
    h1 {
      line-height: 2.8rem;
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: .4rem;
      color: #c8161d;
    }
    span {
      margin-top: .8rem;
      line-height: 2.2rem;
      font-size: 1.4rem;
      font-weight:400;
      color: rgba(134, 134, 134, 1);
    }
  }
  .map {
    margin-top: 1.2rem;
    line-height: 2.2rem;
    font-size: 1.4rem;
    font-weight:500;
    color: rgba(134, 134, 134, 1);
    // display: flex;
  //  .item {
  //     flex:0 0 7rem;
  //   }

  }
  .name{
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight:700;
  }
}
.noData {
    color: #131313;
    text-align: center;
    font-size: 18px;
    padding-top: 25px;
}
@media screen and(min-width: 767px) and (max-width: 991px){
 .product-row {
  /deep/.el-col-sm-12{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
    .container-card {
      margin-bottom: 2rem!important;
    }
   }
 }
  .el-card {
    height: 27rem;

  }
}
@media screen and(min-width:1200px) {
  .el-card {
    height: 25.1rem;

  }
}
  }
</style>
